<template>
  <div class="doc-right">
      <div class="doc-article">
          <h1 class="tit">{{ $t('msg.list_title')  }}</h1>
          <h4>生产环境</h4>
          <pre><code>GET https://api.translasion.com/v1/language_list?type=1</code></pre>
          <h4>测试环境</h4>
          <pre><code>GET https://tapi.translasion.com/v1/language_list?type=1</code></pre>
          <h4 >请求参数</h4>
          <el-table
              :data="apiData"
              stripe
              style="width: 100%">
              <el-table-column
              prop="name"
              label="参数名称"
              >
              </el-table-column>
              <el-table-column
              prop="type"
              label="类型"
              >
              </el-table-column>
              <el-table-column
              prop="isMust"
              label="是否必要">
              </el-table-column>
              <el-table-column
              prop="explain"
              label="释义"
             >
              </el-table-column>
          </el-table>
          <h4 >示例</h4>
          <pre><code>http://api.translasion.com/v1/language_list?type=1</code></pre>
          <h4>返回结果</h4>
          <pre><code>{
    "code": 1000,
    "data": [
        {
            "code": "yue",
            "name": "中文粤语"
        },
        {
            "code": "cmn",
            "name": "中文"
        },
        {
            "code": "vi",
            "name": "tiếng việt"
        },
        {
            "code": "en",
            "name": "English"
        },
        {
            "code": "id",
            "name": "bahasa Indonesia"
        },
        {
            "code": "hi",
            "name": "हिंदी"
        },
        {
            "code": "it",
            "name": "Italiano"
        },
        {
            "code": "hu",
            "name": "Magyar"
        },
        {
            "code": "el",
            "name": "ελληνικά"
        },
        {
            "code": "es",
            "name": "Español"
        },
        {
            "code": "uk",
            "name": "українська"
        },
        {
            "code": "tr",
            "name": "Türkçe"
        },
        {
            "code": "th",
            "name": "ไทย"
        },
        {
            "code": "sr",
            "name": "српски"
        },
        {
            "code": "sv",
            "name": "Svenska"
        },
        {
            "code": "pt",
            "name": "Protuguês"
        },
        {
            "code": "pa",
            "name": "ਪੰਜਾਬੀ"
        },
        {
            "code": "nb",
            "name": "Norge"
        },
        {
            "code": "af",
            "name": "Afrikaans"
        },
        {
            "code": "bn",
            "name": "বাংলা"
        },
        {
            "code": "ms",
            "name": "Bahasa Melayu"
        },
        {
            "code": "ro",
            "name": "româna"
        },
        {
            "code": "lv",
            "name": "latviešu"
        },
        {
            "code": "kn",
            "name": "ಕನ್ನಡ"
        },
        {
            "code": "cs",
            "name": "čeština"
        },
        {
            "code": "ca",
            "name": "catalá"
        },
        {
            "code": "nl",
            "name": "Nederlands"
        },
        {
            "code": "ko",
            "name": "한국의"
        },
        {
            "code": "gu",
            "name": "ગુજરાતી"
        },
        {
            "code": "fi",
            "name": "Suomi"
        },
        {
            "code": "fil",
            "name": "ang Pilipinas"
        },
        {
            "code": "fr",
            "name": "Français"
        },
        {
            "code": "ru",
            "name": "Русский"
        },
        {
            "code": "de",
            "name": "Deutsch"
        },
        {
            "code": "da",
            "name": "Dansk"
        },
        {
            "code": "pl",
            "name": "Polski"
        },
        {
            "code": "is",
            "name": "íslenska"
        },
        {
            "code": "ar",
            "name": "العربية"
        }
    ],
    "message": "success"
}</code></pre>
      </div>
      <div class="docs-subnav">
        <a href="/docs/api/text/single">
          <i class="el-icon-arrow-left"></i>
          单文本翻译
        </a>
        <a href="#">
        </a>
      </div>
  </div>
</template>

<script>
export default {
  name: 'multipleText',
  data () {
    return {
      apiData: [{
        name: 'type',
        type: 'int',
        isMust: '是',
        explain: `语种列表支持类型\n
                  文本翻译:1\n
                  语音转文字:2\n\r
                  语音生成（文字转语音）:3\n
                  OCR:4\n
                  TMS:5\n
                  人工翻译:6`
      }]
    }
  }
}
</script>

<style scoped>
  .doc-right{
      padding:50px 0 0;
  }
  .tit{
      font-size: 26px;
      color: #333;
      font-weight: 400;
      margin: 0 0 60px;
  }
  .doc-article{
      color: #5e6d82;
      font-size: 14px;
  }
  .text{
      line-height: 1.5em;
  }
  .doc-article h4{
      margin: 55px 0 15px;
      font-size: 18px;
      font-weight: 700;
  }
  .doc-article h5{
      font-size: 16px;
      font-weight: 700;
  }
  .mb-15{
      margin-bottom: 15px;
  }
  .doc-article code{
      display: block;
      overflow-x: auto;
      line-height: 1.5;
      font-family: Menlo, Monaco, Consolas, Courier, monospace;
      font-size: 13px;
      padding: 18px 24px;
      background-color: #fafafa;
      border: 1px solid #eaeefb;
      border-radius: 4px;
      -webkit-font-smoothing: auto;
  }
  .doc-article >>> .el-table .cell {
  white-space: pre-line;
  line-height: 15px;
}
.docs-subnav {
        margin-top: 100px;
        padding: 40px 0 120px;
        border-top: 1px solid #ddd;
        display: flex;
        justify-content: space-between;
    }
    .docs-subnav>a {
        position: relative;
        color: #333;
        opacity: .7;
        font-size: 20px;
    }
    .docs-subnav>a:hover{
      opacity: 1;
    }
    .docs-subnav>.r {
        margin-right: 15px;
    }
    .docs-subnav>.r>i {
        left: auto;
        right: -15px;
        margin-top: 1px;
        transform: rotate(135deg);
    }
    a{
      text-decoration: none;
    }
</style>
